import { RoutePattern } from "../../routes/RoutePattern";

export const data = [
  {
    name: "Home",
    to: "/",
    isRoute: false,
  },
  {
    name: "Please Don<span class='font-bebas'>'</span>t Cry",
    to: RoutePattern.ALBUM,
    isRoute: false,
  },
  {
    name: "Merch",
    to: RoutePattern.MERCH,
    isRoute: false,
  },
  {
    name: "Tour",
    to: RoutePattern.TOUR,
    isRoute: false,
  },
];
