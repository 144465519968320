import styled from 'styled-components';
import { ArrowTypes } from './types';

export const Arrow = styled.button<ArrowTypes>`
  // display: none;
  right: ${(props) => props.right && props.right};
  left: ${(props) => props.left && props.left};
  position: absolute;
  z-index: 50;
  top: 33%;
  transform: translateY(-50%);
  width: 40px;
  height: 55px;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: 100;
  outline: none;
  color: white;
  user-select: none;

  :focus {
    outline: none;
  }
  :hover {
    color: red;
  }

  @media (min-width: 768px) {
    top: 35%;
    right: 10%;
    color: black;
    display: block;
  }

  @media (min-width: 1680px) {
    top: 38%;
  }
`;
