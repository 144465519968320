import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import SocialIcons from "../../../components/SocialIcons/SocialIcons";
import $ from "jquery";
import styled from "styled-components";
import Button from "../../../components/Form/Button";
import MENU from "../../../assets/images/Menu_Icon_Black.png";
import POSTER_D from "../../../assets/images/Desktop & Mobile Hero 500x500.gif";
import POSTER_M from "../../../assets/images/Mobile Hero.gif";
import VIDEO_D from "../../../assets/videos/Desktop Hero 2400x1080.mp4";
import VIDEO_M from "../../../assets/videos/Mobile Hero.mp4";
import { isMobile } from "../../../constants";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
}

function isMac() {
  return navigator.userAgent.indexOf('Mac OS X') != -1;
}

const VideoPlayer = styled.video`
  // background:transparent url('${isMobile ? POSTER_M : POSTER_D}') no-repeat 0 0; 
  // -webkit-background-size: contain; 
  // -moz-background-size: contain; 
  // -o-background-size: contain; 
  // background-size: contain;
  // background-position: bottom;
`;

const Showcase = () => {
  const videoRef: any = useRef(null);

  const showSwal = () => {
    Swal.fire({
      // title: 'Error!',
      title: 'Subscribe for Updates',
      input: "email",
      inputPlaceholder: "Enter your email address",
      inputAttributes: {
        autocapitalize: "off"
      },
      confirmButtonColor: "#FF0000",
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      customClass: {
        input: "font-lucida-grande"
      },
      preConfirm: async (email) => {
        try {
          const data = {
            Email: email,
          };
          const response = await sendData(data);

          return response;
        } catch (error) {
          Swal.showValidationMessage(`
            Request Failed: ${error}
          `);
        }
      },
      willOpen: () => {
        scrollTop();
      },
      willClose: () => {
        scrollTop();
      },
      didOpen: function () {
        var offset = document.body.scrollTop;
        document.body.style.top = (offset * -1) + 'px';
        document.body.classList.add('modal--opened');
      },
      didClose: function () {
        var offset = parseInt(document.body.style.top, 10);
        document.body.classList.remove('modal--opened');
        document.body.scrollTop = (offset * -1);
      }
    })
  }

  function sendData(data: any) {
    Swal.fire({
      title: 'SUBMITTING EMAIL',
      willOpen: () => {
        scrollTop();
      },
      willClose: () => {
        scrollTop();
      }
    });
    Swal.showLoading();

    $.ajax({
      url: "https://script.google.com/macros/s/AKfycbw1tapQpeJCuHkunzMCHa9orKqh3cNYLqSZ1Eyz-kEdx6Jih5rWLJdkZZlZrY8Y8Xq9/exec",
      type: "POST",
      data: data
    }).done(function() {
      Swal.fire({
        title: 'SUCCESS',
        text: 'THANK YOU FOR SUBMITTING',
        icon: 'success',
        allowEscapeKey: false,
        confirmButtonColor: '#FF0000',
        willOpen: () => {
          scrollTop();
        },
        willClose: () => {
          scrollTop();
        },
        didOpen: function () {
          var offset = document.body.scrollTop;
          document.body.style.top = (offset * -1) + 'px';
          document.body.classList.add('modal--opened');
        },
        didClose: function () {
          var offset = parseInt(document.body.style.top, 10);
          document.body.classList.remove('modal--opened');
          document.body.scrollTop = (offset * -1);
        }
      });
    });
  }

  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

  setTimeout(function() {
    const videoPlayer = videoRef.current as HTMLVideoElement;

    videoPlayer.play();
    
    showSwal();
    scrollTop();
    $("#privacy-container").css('display', 'block');
  }, 100);

// ${iOS() ? isSafari() ? "h-[95vh]" : "h-[86vh]" : "h-[93vh]"} xs:h-screen sm:
// ${isSafari() ? "h-[93vh]" : "h-screen"} md:

  return (
    <Box
      className={`relative flex flex-col lg:flex-row justify-center items-center ${iOS() ? isSafari() ? "h-[90vh]" : "h-[88vh]" : "h-[93vh]"} md:h-screen w-full overflow-hidden bg-[#FFCF05] touch-none`}
    >
        <Box className={`relative flex justify-center h-full w-full`}>
          <VideoPlayer
            id="video_player"
            ref={videoRef}
            preload="metadata"
            playsInline
            // autoPlay
            loop
            muted
            poster={isMobile ? POSTER_M : ""}
            src={isMobile ? VIDEO_M : VIDEO_D}
            className="h-full w-screen object-cover object-bottom outline-none"
          ></VideoPlayer>
        </Box>
        <Box id="privacy-container" className="absolute top-5 md:top-auto right-5 md:right-3 bottom-1 md:bottom-2 flex flex-col z-[60] hidden">
          <a href="https://www.rocnation.com/privacy-policy/" target="_blank" className="!font-span-regularcomp uppercase m-1 text-xl 2xl:text-2xl font-bold text-center text-black !tracking-tighter">privacy policy</a>
        </Box>
    </Box>
  );
};

export default Showcase;
