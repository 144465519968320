import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { YoutubeItemTypes } from "./types";
import styled from "styled-components";
import { faAngleLeft, faAngleRight, faChevronRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Arrow } from "./styled.components";
import IMG_1 from "../../assets/images/artwork/Asteroids_Single_Cover_3000x3000_PA_Sticker.jpg";
import IMG_2 from "../../assets/images/artwork/Back_In_My_Bag_3000x3000.jpg";
import IMG_3 from "../../assets/images/artwork/Stand_Tall_3000x3000.jpg";
import IMG_4 from "../../assets/images/artwork/Rap-Badu_3am 440.png";
import IMG_5 from "../../assets/images/artwork/Back_In_My_Bag_440.png";

import THUMB_1 from "../../assets/images/thumbnails/Asteroids thumb.jpg";
import THUMB_3 from "../../assets/images/thumbnails/Stand Tall.png";
import THUMB_4 from "../../assets/images/thumbnails/BIMB video thumb.png";

import { isMobile } from "../../constants";

const data = [
  {
    // videoId: "https://rapsody.lnk.to/3AMLiveVideo",
    videoId: "tj4pk9_jPPk",
    title: "Back In My Bag",
    subText: "",
    cover: IMG_5,
    thumbnail: THUMB_4,
    stream: "https://rapsody.lnk.to/BIMBStream",
  },
  {
    // videoId: "https://rapsody.lnk.to/3AMLiveVideo",
    videoId: "NE6fHCVjzXc",
    title: "3:AM",
    subText: "Featuring Erykah Badu",
    cover: IMG_4,
    thumbnail: THUMB_3,
    stream: "https://rapsody.lnk.to/3AM",
  },
  {
    // videoId: "https://rapsody.lnk.to/StandTallVideo",
    videoId: "kelMJhur2cc",
    title: "Stand Tall",
    subText: "",
    cover: IMG_3,
    thumbnail: THUMB_3,
    stream: "https://rapsody.lnk.to/StandTall",
  },
  {
    // videoId: "https://rapsody.lnk.to/AsteroidsMusicVideo",
    videoId: "khiwsdYyx7Y",
    title: "Asteroids",
    subText: "Produced by Hit-Boy",
    cover: IMG_1,
    thumbnail: THUMB_1,
    stream: "https://rapsody.lnk.to/Asteroids",
  },
  // {
  //   videoId: "khiwsdYyx7Y",
  //   title: "Back In My Bag",
  //   subText: "Produced by Hit-Boy",
  //   thumbnail: IMG_2,
  //   stream: "#",
  // },
];

const Iframe = styled.iframe`
  // width: 100% !important;
  margin: 0 !important;
  margin-bottom: 1rem !important;
`;

const IframeDiv = styled.div`
  // width: 100% !important;
  margin: 0 !important;
  margin-bottom: 1rem !important;
  overflow: hidden;
  height: 350px;

  @media (min-width: 768px) {
    height: auto;
  }
`;

const ImageDiv = styled.img`

`;

const VideoItem = styled.div`
  max-width: 100%;
  width: 100%;
  height: 350px;
  margin: auto;

  ::after {
    content: "";
    background: transparent;
    width: 15%;
    height: 100%;
    min-height: 1px;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (min-width: 480px) {
    height: 400px;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    height: 390px;
    max-width: 50%;
  }

  @media (min-width: 1280px) {
    height: 405px;
    max-width: 50%;
  }

  @media (min-width: 1370px) {
    height: 425px;
  }

  @media (min-width: 1440px) {
    height: 450px;
  }

  @media (min-width: 1680px) {
    height: 550px;
    max-width: 50%;
  }
`;

export default function YoutubeSlider() {
  const [youtubeItems] = useState<YoutubeItemTypes[]>(data);
  // useEffect(() => {
  //   //   get the youtube playlist
  //   handleRequest();
  // }, []);

  // async function handleRequest() {
  //   const playlist_id = "PLE4qSjszj5hji9uCMU6oXGfN4aFzKox2s";
  //   const youtube_api = process.env.REACT_APP_YOUTUBE_API;
  //   const video_list = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlist_id}&fields=items(snippet(resourceId%2FvideoId%2Cthumbnails%2Fmedium%2Furl%2Ctitle))&key=${youtube_api}`;

  //   const { data } = await axios.get(video_list);

  //   const filteredData: YoutubeItemTypes[] = [];

  //   data.items.map((item: any) => {
  //     filteredData.push({
  //       videoId: item.snippet.resourceId.videoId,
  //       title: item.snippet.title,
  //     });
  //   });

  //   // setYoutubeItems(filteredData);
  // }

  const renderArrowPrev = (
    onClickHandler: any,
    hasPrev: boolean,
    label: string
  ) => (
    <Arrow
      type="button"
      onClick={onClickHandler}
      left="5%"
      title={label}
      className="from-lef"
    >
      <FontAwesomeIcon icon={faAngleLeft as any} />
    </Arrow>
  );

  const renderArrowNext = (
    onClickHandler: any,
    hasNext: boolean,
    label: string
  ) => (
    <Arrow
      type="button"
      onClick={onClickHandler}
      right="0%"
      title={label}
      className="from-righ"
    >
      <FontAwesomeIcon icon={faChevronRight as any} />
    </Arrow>
  );

  const items = youtubeItems.map((item, idx) => (
    <VideoItem
      key={idx}
      className="flex justify-center items-center flex-col text-white"
    >
      {!item.videoId ? (
        <IframeDiv className="flex justify-center items-center cursor-pointer"
        >
          <ImageDiv src={item.thumbnail} className="w-full h-full xl:h-auto object-cover"
          />
        </IframeDiv>
      ) : (
        <Iframe
          className="!w-full h-full touch-none"
          src={`https://www.youtube.com/embed/${item.videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      {/* <div className="flex justify-center items-center cursor-pointer"
        onClick={() => {
          window.open(item.videoId);
        }}
      >
        <img src={item.thumbnail} className="w-full h-full"
        />
        <div className="absolute">
          <FontAwesomeIcon
            icon={faPlay as any}
            className="text-6xl text-[#FF0000] cursor-pointer"
            onClick={() => {
              if (item.videoId) {
                window.open(item.videoId);
              }
            }}
          />
        </div>
      </div> */}
      <div className="flex justify-between w-full mt-3">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (item.stream) 
              window.open(item.stream);
          }}
        >
          <img
            src={item.cover}
            className="!w-24 !h-24 ml-[1px] md:ml-0"
          />
        </div>
        <div className="flex flex-col justify-center items-end">
          <h2
            className="font-bold tracking-tighter text-black text-lg md:text-xl"
          >
            "{item.title}"
          </h2>
          <h2
            className="text-black tracking-tighter text-lg md:text-xl"
            dangerouslySetInnerHTML={{__html: !item.subText ? "&nbsp;" : item.subText}}
          ></h2>
          {/* <a
            // href={`https://www.youtube.com/${item.videoId}`}
            href={item.stream}
            target="_blank"
            rel="noreferrer"
            className="uppercase font-calibre-medium underline text-m md:text-xl"
          >
            stream / download now
          </a> */}
        </div>
      </div>
    </VideoItem>
  ));

  return (
    <Carousel
      infiniteLoop
      // swipeable
      // emulateTouch
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      showIndicators={false}

      renderArrowNext={renderArrowNext}
      // renderArrowPrev={renderArrowPrev}
    >
      {items}
    </Carousel>
  );
}
