import {
  faApple,
  faFacebookF,
  faInstagram,
  faSpotify,
  faTwitter,
  faYoutube,
  faTiktok,
  faAmazon
} from "@fortawesome/free-brands-svg-icons";
import { faMusic } from "@fortawesome/free-solid-svg-icons";
import Tidal from "../../assets/images/Tidal_Icon.png";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faYoutube,
    link: "https://youtube.com/@rapsody?si=pTFlrBqwYd5IKkAE",
  },
  {
    icon: faSpotify,
    link: "https://open.spotify.com/artist/6NL31G53xThQXkFs7lDpL5?si=mfD72q3VRRy_zoqyTthccg",
  },
  {
    icon: faApple,
    link: "https://music.apple.com/us/artist/rapsody/6283845",
  },
  {
    img: Tidal,
    link: "https://tidal.com/browse/artist/3599189",
  },
  {
    icon: faAmazon,
    link: "https://amazon.com/music/player/artists/B0012B63VQ/rapsody?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_U0RR5maOlRyL5xWbGagibnQLF",
  },
];
