import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import SocialIcons from "../../../components/SocialIcons/SocialIcons";
import {
  faPlay,
  faPause,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import styled from "styled-components";
import Button from "../../../components/Form/Button";
import LOGO from "../../../assets/images/Rapsody_Name_Logo_Red.png";
import SONG from "../../../assets/images/Asteroids sq.png";

import CASSETTE from "../../../assets/images/CASSETTE_TEE_Rap_Tape_1k.png";
import BUY_NOW from "../../../assets/images/Buy_Now.png";
import LOADING_BAR from "../../../assets/images/Rapsody Loading GIF.gif";
import V2_IMG from "../../../assets/images/V2 PDC png.png";
import { isMobile } from "../../../constants";

import AUDIO from "../../../assets/audio/09 3 AM (MAIN) HRA SING MASTER.mp3";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
}

const Slider = styled.input`
  appearance: none;

  ::-webkit-slider-thumb {
    appearance: none;
    height: 12px;
    width: 12px;
    background-color: #f00;
    border-radius: 50%;
    border: 1px solid #f00;
    cursor: pointer;
  }
`;

const Showcase = () => {
  const [inputValue, setInputValue] = useState('');
  const [mouseDownOnSlider, setMouseDownOnSlider] = useState(false);
  const progressRef: any = useRef(null);
  const loadingPercent: any = useRef(null);
  const musicPlayerSliderRef: any = useRef(null);
  const musicPlayerRef: any = useRef(null);
  const [musicIsPlaying, setMusicIsPlaying] = useState(false);
  const [audio] = useState(new Audio(AUDIO));

  const showSwal = () => {
    Swal.fire({
      // title: 'Error!',
      title: 'Subscribe for Updates',
      input: "email",
      inputPlaceholder: "Enter your email address",
      inputAttributes: {
        autocapitalize: "off"
      },
      confirmButtonColor: "#FF0000",
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      customClass: {
        input: "font-lucida-grande"
      },
      preConfirm: async (email) => {
        try {
          const data = {
            Email: email,
          };
          const response = await sendData(data);

          return response;
        } catch (error) {
          Swal.showValidationMessage(`
            Request Failed: ${error}
          `);
        }
      },
      willOpen: () => {
        scrollTop();
      },
      willClose: () => {
        scrollTop();
      }
    })
  }

  function sendData(data: any) {
    Swal.fire({
      title: 'SUBMITTING EMAIL',
      willOpen: () => {
        scrollTop();
      },
      willClose: () => {
        scrollTop();
      }
    });
    Swal.showLoading();

    $.ajax({
      url: "https://script.google.com/macros/s/AKfycbw1tapQpeJCuHkunzMCHa9orKqh3cNYLqSZ1Eyz-kEdx6Jih5rWLJdkZZlZrY8Y8Xq9/exec",
      type: "POST",
      data: data
    }).done(function() {
      Swal.fire({
        title: 'SUCCESS',
        text: 'THANK YOU FOR SUBMITTING',
        icon: 'success',
        allowEscapeKey: false,
        confirmButtonColor: '#FF0000',
        willOpen: () => {
          scrollTop();
        },
        willClose: () => {
          scrollTop();
        }
      });
    });
  }

  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

  // showSwal();

// ${iOS() ? isSafari() ? "h-[95vh]" : "h-[86vh]" : "h-[93vh]"} xs:h-screen sm:
// ${isSafari() ? "h-[93vh]" : "h-screen"} md:

  useEffect(() => {
    !musicIsPlaying ? audio.pause() : audio.play();
  }, [musicIsPlaying]);

  useEffect(() => {
    audio.addEventListener('ended', () => setMusicIsPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setMusicIsPlaying(false));
    };
  }, []);

  useEffect(() => {
    audio.addEventListener('timeupdate', function () {
      const musicSlider = musicPlayerSliderRef.current as HTMLInputElement;
      musicSlider.value = (this.currentTime / this.duration * 100).toString();

      let totalSeconds = Math.floor(this.currentTime);
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;

      let minutesSTR = String(minutes).padStart(1, "0");
      let hoursSTR = String(hours).padStart(1, "0");
      let secondsSTR = String(seconds).padStart(2, "0");
      let result = minutesSTR + ":" + secondsSTR;
      $("#musicCurrentTime").text(result);

      if (this.currentTime == this.duration) {
        setMusicIsPlaying(false);
      }
    });

    audio.addEventListener('loadeddata', function() {
      const musicSlider = musicPlayerSliderRef.current as HTMLInputElement;
      musicSlider.value = "0";

      let totalSeconds = Math.floor(this.duration);
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;

      let minutesSTR = String(minutes).padStart(1, "0");
      let hoursSTR = String(hours).padStart(1, "0");
      let secondsSTR = String(seconds).padStart(2, "0");
      let result = minutesSTR + ":" + secondsSTR;
      $("#musicDuration").text(result);
    });
  }, [])

  return (
    <Box
      className={`relative flex flex-col lg:flex-row justify-center items-center ${iOS() ? isSafari() ? "h-[90vh]" : "h-[88vh]" : "h-[93vh]"} xs:h-screen sm:h-screen md:h-screen w-full overflow-hidden py-0 bg-[#FFCF05]`}
    >
        {/* ${isSafari()? "justify-center" : "justify-center"} md: */}
      <Box className={`relative flex flex-col justify-center items-center h-full`}>
        <img src={V2_IMG} className="w-9/12 md:w-1/4 mt-1 lg:mt-0 mb-5" />
        <Box className="flex flex-col justify-center items-center text-[#f00] text-3xl font-bold my-10 md:my-14">
          <div className="mb-8">Dear Loved Ones,</div>
          <div>Here's An Exclusive...</div>
        </Box>
        <Box className="w-[18rem] md:w-[22rem]">
          <Slider
            ref={musicPlayerSliderRef}
            type="range"
            defaultValue="0"
            min="0"
            max="100"
            className="w-full h-0.5 bg-black"
            disabled
            onMouseDown={() => {
              setMouseDownOnSlider(true);
            }}
            onMouseUp={() => {
              setMouseDownOnSlider(false);
            }}
          />
          <div className="flex justify-between text-md text-black mt-3"><span id="musicCurrentTime">0:00</span><span id="musicDuration">0:00</span></div>
          <Box className="flex justify-center items-center -mt-4">
            <button
              className="bg-[#f00] text-black px-4 py-3 flex justify-center items-center rounded-full"
              onClick={() => {
                // const musicPlayer = musicPlayerRef.current as HTMLAudioElement;

                // if (!musicIsPlaying) {
                //   audio.play();
                // } else {
                //   audio.pause();
                // }
                setMusicIsPlaying(prevStatus => !prevStatus);
              }}
            ><FontAwesomeIcon icon={(!musicIsPlaying ? faPlay : faPause) as any} /></button>
          </Box>
          {/* <audio
            ref={musicPlayerRef}
            className="hidden"
            onTimeUpdate={(e) => {
              const musicSlider = musicPlayerSliderRef.current as HTMLInputElement;
              musicSlider.value = (e.currentTarget.currentTime / e.currentTarget.duration * 100).toString();


              let totalSeconds = Math.floor(e.currentTarget.currentTime);
              let hours = Math.floor(totalSeconds / 3600);
              totalSeconds %= 3600;
              let minutes = Math.floor(totalSeconds / 60);
              let seconds = totalSeconds % 60;

              let minutesSTR = String(minutes).padStart(1, "0");
              let hoursSTR = String(hours).padStart(1, "0");
              let secondsSTR = String(seconds).padStart(2, "0");
              let result = minutesSTR + ":" + secondsSTR;
              $("#musicCurrentTime").text(result);

              if (e.currentTarget.currentTime == e.currentTarget.duration) {
                setMusicIsPlaying(false);
              }
            }}
            onLoadedData={(e) => {
              const musicSlider = musicPlayerSliderRef.current as HTMLInputElement;
              musicSlider.value = "0";

              let totalSeconds = Math.floor(e.currentTarget.duration);
              let hours = Math.floor(totalSeconds / 3600);
              totalSeconds %= 3600;
              let minutes = Math.floor(totalSeconds / 60);
              let seconds = totalSeconds % 60;

              let minutesSTR = String(minutes).padStart(1, "0");
              let hoursSTR = String(hours).padStart(1, "0");
              let secondsSTR = String(seconds).padStart(2, "0");
              let result = minutesSTR + ":" + secondsSTR;
              $("#musicDuration").text(result);
            }}
          /> */}
        </Box>
        <Box className="absolute bottom-10 md:bottom-1">
          <SocialIcons />
        </Box>
        {/* <Box>
          <a href="https://www.rocnation.com/privacy-policy/" target="_blank" className="py-2 lg:py-1 px-1 md:px-3 uppercase m-1 text-md md:text-md 2xl:text-lg text-center text-black tracking-tight">privacy policy</a>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Showcase;
