import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Footer, Navigation, Preloader } from "./components";
import Libs from "./Libs";
import AppRoutes from "./routes/AppRoutes";
import { setCookie } from "./util";

export default function App() {
  // const [showMenu, setShowMenu] = useState(true);
  useEffect(() => {
    Libs(); // all libs

    // if (window.location.pathname.includes('/lovedones')) {
    //   setShowMenu(false);
    // }
  }, []);

  return (
    <>
      <Router>
        {/* <Preloader /> */}
        <Navigation />
        <Routes>
          <Route path="*" element={<AppRoutes />} />
        </Routes>
      </Router>
    </>
  );
}
