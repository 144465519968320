import BEIGE_T from "../../assets/images/merch/Beige_Tee.png";
import BEIGE_TH from "../../assets/images/merch/Beige_Tee_Hover.png";
import BLACK_T from "../../assets/images/merch/Black_Tee.png";
import BLACK_TH from "../../assets/images/merch/Black_Tee_Hover.png";
import JOURNAL from "../../assets/images/merch/Journal.png";
import JOURNAL_H from "../../assets/images/merch/Journal_Hover.png";
import HOODIE from "../../assets/images/merch/Hoodie.png";
import HOODIE_H from "../../assets/images/merch/Hoodie_Hover.png";
import TISSUE from "../../assets/images/merch/Tissue_Box.png";
import TISSUE_H from "../../assets/images/merch/Tissue_Box_Hover.png";
import YVINYL from "../../assets/images/merch/rap assets misc3.png";
import BVINYL from "../../assets/images/merch/rap assets misc5.png";
import RVINYL from "../../assets/images/merch/red vinyl.png";
import CASSETTE from "../../assets/images/merch/rap cassette.png";
import COVER from "../../assets/images/merch/PLEASE_DONT_CRY_Album_Cover.png";
import CD_COVER from "../../assets/images/merch/Rapsody CD Mockup.png";
import DOWNLOAD from "../../assets/images/merch/PLEASE_DONT_CRY_Dig Download img.png";
import LOVEDONE from "../../assets/images/merch/rap assets misc (10).png";
import RAP_MISC from "../../assets/images/merch/rap-misc.png";
import IHR_TEE from "../../assets/images/merch/rap I Luv Rap shirt.png";
import PP_HAT from "../../assets/images/merch/rap paper planes hat.png";
import PP_SHIRT from "../../assets/images/merch/rap paper planes shirt.png";
import RAP_TISSUE from "../../assets/images/merch/rap tissue.png";

export const data = [
  {
    name: "Yellow Vinyl",
    img: YVINYL,
    link: "https://rapsody.lnk.to/PDCYellow",
    price: "$35",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "Black Vinyl",
    img: BVINYL,
    link: "https://Rapsody.lnk.to/P2P",
    price: "Proud 2 Pay",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "Red Vinyl",
    img: RVINYL,
    link: "https://rapsody.lnk.to/PDCRed",
    price: "$35.98",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "CD",
    img: CD_COVER,
    link: "https://rapsody.lnk.to/PDCCD",
    price: "$11.99",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "Cassette",
    img: CASSETTE,
    link: "https://rapsody.lnk.to/PDCCassette",
    price: "$10.99",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "Please Don't Cry<br/>Digital Download",
    img: DOWNLOAD,
    link: "https://Rapsody.lnk.to/DigitalDownload",
    price: "$11.99",
    width: "80%",
    buyButton: false,
    showName: true,
    show: true,
  },
  {
    name: "Dear Loved One Tee",
    img: LOVEDONE,
    link: "https://rapsody.lnk.to/LovedOnes",
    price: "$39.99",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "Grow From The Pain",
    img: RAP_MISC,
    link: "https://rapsody.lnk.to/GrowFromThePain",
    price: "$39.99",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "I Heart Rap Tee",
    img: IHR_TEE,
    link: "https://rapsody.lnk.to/IHeartRapMerch",
    price: "$45",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "Paper Planes Hat",
    img: PP_HAT,
    link: "https://rapsody.lnk.to/PaperPlanes",
    price: "$55",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "Paper Planes Shirt",
    img: PP_SHIRT,
    link: "https://rapsody.lnk.to/PaperPlanes",
    price: "$58",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  {
    name: "Tissue Box",
    img: RAP_TISSUE,
    link: "https://rapsody.lnk.to/TissueBox",
    price: "$29.99",
    width: "80%",
    buyButton: false,
    showName: false,
    show: true,
  },
  // {
  //   name: "I Heart Rap Sweatshirt",
  //   img: IHR_TEE,
  //   link: "https://rapsody.lnk.to/Sweatshirt",
  //   price: "$39.99",
  //   width: "80%",
  //   buyButton: false,
  //   showName: false,
  //   show: true,
  // },
  // {
  //   name: "Beige Tee",
  //   img: BEIGE_T,
  //   alt: BEIGE_TH,
  //   link: "https://google.com",
  //   price: "$45",
  //   width: "80%",
  //   buyButton: false,
  //   show: false,
  // },
  // {
  //   name: "Black Tee",
  //   img: BLACK_T,
  //   alt: BLACK_TH,
  //   link: "#",
  //   price: "$45",
  //   width: "80%",
  //   buyButton: false,
  //   show: false,
  // },
  // {
  //   name: "Journal",
  //   img: JOURNAL,
  //   alt: JOURNAL_H,
  //   link: "#",
  //   price: "$10",
  //   height: 30,
  //   buyButton: false,
  //   show: false,
  // },
  // {
  //   name: "Hoodie",
  //   img: HOODIE,
  //   alt: HOODIE_H,
  //   link: "#",
  //   price: "$50",
  //   height: 30,
  //   buyButton: false,
  //   show: false,
  // },
  // {
  //   name: "Tissue",
  //   img: TISSUE,
  //   alt: TISSUE_H,
  //   link: "#",
  //   price: "$5",
  //   height: 25,
  //   buyButton: false,
  //   show: false,
  // },
];
