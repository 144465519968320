interface TourTypes {
    id: string;
    title: string;
    tours: TourData[];
}

interface TourData {
    date?: string;
    location?: string;
    place?: string;
    link?: string;
}

export const data: TourTypes[] = [
    {
        id: "NA",
        title: "North America",
        tours: [
            {
                date: "9/19",
                location: "Philadelphia, PA",
                place: "The Foundry at The Fillmore",
                link: "https://www.ticketmaster.com/event/020060AEAEB12E00"
            },
            {
                date: "9/20",
                location: "Cambridge, MA",
                place: "The Sinclair",
                link: "https://www.axs.com/artists/796229/rapsody-tickets"
            },
            {
                date: "9/21",
                location: "Hartford, CT",
                place: "The Webster - Underground",
                link: "https://www.etix.com/ticket/p/76961053/rapsody-please-dont-cry-hartford-the-webster-underground"
            },
            {
                date: "9/22",
                location: "New York, NY",
                place: "Bowery Ballroom",
                link: "https://www.ticketmaster.com/event/000060AEBED537C7"
            },
            {
                date: "9/26",
                location: "Washington, DC",
                place: "Howard Theatre",
                link: "https://www.ticketweb.com/event/rapsody-howard-theatre-tickets/13514424?pl=howardtheatre&refid=artist"
            },
            {
                date: "9/29",
                location: "Toronto, ON",
                place: "Adelaide Hall",
                link: "https://admitone.com/events/rapsody-toronto-9549719"
            },
            {
                date: "10/1",
                location: "Detroit, MI",
                place: "Saint Andrew's Hall - The Shelter",
                link: "https://www.ticketmaster.com/event/080060AAE57F550C"
            },
            {
                date: "10/2",
                location: "Chicago, IL",
                place: "The Promontory",
                link: "https://rapsodychicago.eventbrite.com"
            },
            {
                date: "10/5",
                location: "Atlanta, GA",
                place: "Masquerade - Hell Stage",
                link: "https://www.ticketmaster.com/event/0E0060AEA7C536EE"
            },
            {
                date: "10/8",
                location: "Houston, TX",
                place: "House of Blues Bronze Peacock",
                link: "https://www.ticketmaster.com/event/3A0060AD977B1CA7"
            },
            {
                date: "10/9",
                location: "Dallas, TX",
                place: "HOB Dallas Cambridge Room",
                link: "https://www.ticketmaster.com/event/0C0060AF9E4F3BBA"
            },
            {
                date: "10/13",
                location: "Denver, CO",
                place: "Cervantes' Other Side",
                link: "https://etix.com/ticket/p/56233118"
            },
            {
                date: "10/17",
                location: "Seattle, WA",
                place: "The Crocodile - Showroom",
                link: "https://www.ticketweb.com/event/rapsody-please-dont-cry-the-crocodile-tickets/13517824?pl=shealy"
            },
            {
                date: "10/18",
                location: "Portland, OR",
                place: "Hawthorne Theatre",
                link: "https://www.etix.com/ticket/p/40576334/rapsody-portland-hawthorne-theatre"
            },
            {
                date: "10/20",
                location: "Oakland, CA",
                place: "Crybaby",
                link: "https://www.ticketweb.com/event/please-dont-cry-rapsody-tour-crybaby-tickets/13513074"
            },
            {
                date: "10/21",
                location: "Los Angeles, CA",
                place: "El Rey Theatre",
                link: "https://www.axs.com/events/569536/rapsody-tickets"
            },
            {
                date: "10/25",
                location: "Charlotte, NC",
                place: "Amos' Southend",
                link: "https://www.etix.com/ticket/p/53654274/rapsody-please-dont-crytour-charlotte-amossouthend"
            },
            {
                date: "10/27",
                location: "Raleigh, NC",
                place: "Lincoln Theatre",
                link: "https://www.etix.com/ticket/p/83589084/rapsody-please-dont-crytour-raleigh-lincoln-theatre"
            },
        ],
    }, {
        id: "EU",
        title: "Europe",
        tours: [
            {
                date: "9/6",
                location: "Berlin, Germany",
                place: "Frannz Club",
                link: "https://www.livenation.de/artist-rapsody-1069916"
            },
            {
                date: "9/9",
                location: "Amsterdam, Netherlands",
                place: "Melkweg OZ",
                link: "https://www.ticketmaster.nl/event/305703?brand=nl_melkweg"
            },
            {
                date: "9/12",
                location: "Paris, France",
                place: "Trabendo",
                link: "https://www.livenation.fr/artist-rapsody-1069916"
            },
            {
                date: "9/14",
                location: "Geneva, Switzerland",
                place: "Salle Ernest Ansermet",
                link: "https://infomaniak.events/shop/NagBx2i9IK/"
            },
            {
                date: "9/15",
                location: "London, UK",
                place: "Burgess Park",
                link: "https://ra.co/events/1855330"
            },
        ]
    // }, {
    //     id: "AU",
    //     title: "Australia",
    //     tours: [
    //         {
    //             date: "coming soon",
    //             location: "",
    //             place: ""
    //         }
    //     ]
    }, {
        id: "ZA",
        title: "South America",
        tours: [
            {
                date: "8/30",
                location: "Sao Paulo, Brazil",
                place: "Audio",
                link: "https://urldefense.com/v3/__https://www.ticket360.com.br/evento/29357/ingressos-para-manto-da-noite__;!!FBl7RhjNdtJEi0k!uR-FJguut7-jv2Vi-I6u4KQ6_oSgabNXLY0GTQu3KkBGrBx6ZtjBb-SIGwd7Vof_WW_ckHFXQBoiNWsGtw$"
            },
            {
                date: "9/1",
                location: "Rio de Janeiro, Brazil",
                place: "Circo Voador",
                link: "https://urldefense.com/v3/__https://www.eventim.com.br/artist/rapsody/rapsody-no-circo-voador-3689448/?affiliate=C2Q__;!!FBl7RhjNdtJEi0k!uR-FJguut7-jv2Vi-I6u4KQ6_oSgabNXLY0GTQu3KkBGrBx6ZtjBb-SIGwd7Vof_WW_ckHFXQBqzUkQ-vQ$"
            }
        ]
    }
];


export const includedCountries = [
    {
        id: "US",
        value: 1,
        hoverable: false,
        rollOverColor: null,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "CA",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "MX",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "ZA",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "AU",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "GB",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "BE",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "BG",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "CZ",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "DK",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "DE",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "EE",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "IE",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "EL",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "ES",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "FR",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "HR",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "IT",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "CY",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "LV",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "LT",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "LU",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "HU",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "MT",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "NL",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "AT",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "PL",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "PT",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "RO",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "SI",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "SK",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "FI",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "SE",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "IS",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "LI",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "NO",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }, {
        id: "CH",
        value: 1,
        polygonSettings: {
            fill: "#FFCF05",
        }
    }
];