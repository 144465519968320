import { Box } from "../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import YoutubeSlider from "../../components/YoutubeSlider/YoutubeSlider";
import $ from "jquery";
import styled from "styled-components";
import Button from "../../components/Form/Button";
import MENU from "../../../assets/images/Menu_Icon_Black.png";
import PDC_RAP from "../../assets/images/Desktop & Mobile Hero 500x500.gif";
import PDC_ALBUM from "../../assets/images/PDC_4th_Studio_Album.png";
import { isMobile } from "../../constants";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
}

const HeadContainer = styled.div`
  // @media (min-width: 1280px) {
  //   width: 42%;
  // }

  // @media (min-width: 1360px) {
  //   width: 33%;
  // }

  // @media (min-width: 1460px) {
  //   width: 30%;
  // }

  // @media (min-width: 1530px) {
  //   width: 25%;
  // }

  // @media (min-width: 1680px) {
  //   width: 16%;
  // }
`;

const PDC_IMG = styled.img`
  @media (max-width: 1279px) {
    margin-left: 3rem;
  }
`;

const Album = () => {
// ${iOS() ? isSafari() ? "h-[95vh]" : "h-[86vh]" : "h-[93vh]"} xs:h-screen sm:
// ${isSafari() ? "h-[93vh]" : "h-screen"} md:

  return (
    <Box
      className={`relative flex flex-col lg:flex-row justify-start items-center md:items-start ${iOS() ? isSafari() ? "h-[95vh]" : "h-[88vh]" : "h-[93vh]"} xl:h-screen w-full pt-8 md:py-5 bg-[#FFCF05]`}
    >
        <Box className={`relative flex flex-col justify-start items-center h-max w-[90%] md:w-2/3 xl:w-full mx-40 my-10 md:my-5`}>
            <Box className="border-4 border-black flex flex-col xl:flex-row h-auto xl:h-44 w-full">
                <HeadContainer className="flex justify-start md:justify-center xl:justify-start items-center xl:w-1/2">
                    <img src={PDC_RAP} className="w-24 h-24 md:h-32 md:w-auto xl:w-60 xl:w-32 border-4 border-black m-3 md:m-5" />
                    <img src={PDC_ALBUM} className="h-28 md:h-44 w-44 md:w-auto py-4 md:py-5" />
                </HeadContainer>
                {/* <PDC_IMG src={PDC_ALBUM} className="h-auto w-96 py-5 hidden md:block" /> */}
                <Box className="flex flex-col justify-center items-center md:items-end w-full xl:w-1/2">
                    <Box className="flex flex-col justify-center items-center w-full xl:w-auto xl:mx-5">
                        <div className="text-center tracking-tighter uppercase font-span-regularcomp font-bold text-sm md:text-md xl:text-lg">
                          Available now on a<br />streaming platform of your choice
                        </div>
                        {/* <SocialIcons /> */}
                        <button
                          className="uppercase bg-[#FF0000] text-white px-5 py-1 mt-2 rounded-sm border border-[#FF0000] mb-3 xl:mb-0 font-bold"
                          onClick={() => {
                            window.open('https://rapsody.lnk.to/PleaseDontCry');
                          }}
                        >Out Now</button>
                    </Box>
                </Box>
            </Box>
            <Box className="mt-5 md:mt-8 mb-5">
                <YoutubeSlider />
            </Box>
        </Box>
    </Box>
  );
};

export default Album;
