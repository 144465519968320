import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase4";

export default function Home() {
  return (
    <Box className="text-white">
      <Showcase />
    </Box>
  );
}
