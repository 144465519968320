import { Box } from "../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import YoutubeSlider from "../../components/YoutubeSlider/YoutubeSlider";
import $ from "jquery";
import styled from "styled-components";
import Button from "../../components/Form/Button";
import BEIGE_T from "../../assets/images/merch/Beige_Tee.png";
import BEIGE_TH from "../../assets/images/merch/Beige_Tee_Hover.png";
import { isMobile } from "../../constants";
import { data } from "./data";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const Item = styled.a``;

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
}

const Merch = () => {
    const [hovered, setHovered] = useState<number | null>(null);
// ${iOS() ? isSafari() ? "h-[95vh]" : "h-[86vh]" : "h-[93vh]"} xs:h-screen sm:
// ${isSafari() ? "h-[93vh]" : "h-screen"} md:

  return (
    <div
      className="text-white w-full flex flex-col justify-center items-center bg-[#FF0000] min-h-screen z-50 pt-10 md:pt-0"
    >
      <div className="w-full md:w-10/12 flex flex-col md:flex-row md:flex-wrap justify-center items-center z-10 md:mt-14 mb-10 md:mb-5">
        {data.map((item, idx) => (
          <>
            {!item.show ? null : (
                <Item
                    key={idx}
                    href={item.price ? undefined : item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-center relative w-2/3 md:w-2/6 cursor-pointer my-8 md:my-5"
                    onClick={() => {
                        // if (hovered === idx) {
                        //     setHovered(null);
                        // } else {
                            setHovered(idx);
                        // }
                    }}
                    onMouseOver={() => setHovered(idx)}
                    onMouseOut={() => setHovered(null)}
                >
                    {/* <span className=" text-white capitalize mb-5 block md:hidden">
                        {item.name}
                    </span> */}
                    <img
                        src={item.img}
                        alt={item.name}
                        className={`w-full h-full object-contain px-2 md:px-10`}
                    />
                    {!item.price ? null : (
                    <div
                        id={`id-${item.name.toLowerCase().replace(/ /g, "-").replace(/\'/g, "")}`}
                        className={`${
                            hovered === idx ? "opacity-100" : "opacity-0"
                        } md:px-2 artist-bio position-center w-full h-full flex flex-col justify-center items-center transition-all duration-300 hover:bg-[rgba(255,0,0,0.5)]`}
                        onClick={(e) => {
                          var isShown = parseInt($(e.target).css('opacity'));
                          if (item.link && isShown > 0) {
                            window.open(item.link);
                          }
                        }}
                    >
                        <div
                          className={`px-10 text-5xl md:text-7xl text-[#FFCF05] font-bold`}
                          style={{textShadow: "3px 3px 3px rgb(0 0 0 / 0.5"}}
                          // dangerouslySetInnerHTML={{__html: item.price}}
                          onClick={(e) => {
                            // var isShown = parseInt($(e.target).offsetParent().css('opacity'));
                            // if (item.link && isShown > 0) {
                            //   window.open(item.link);
                            // }
                          }}
                        >
                          {!item.showName ? null : (
                            <div className="text-3xl md:text-4xl" dangerouslySetInnerHTML={{__html: item.name}}></div>
                          )}
                          {item.price}
                        </div>
                        {!item.buyButton ? null : (
                        <div className="mt-2">
                            <a
                            href={item.link}
                            rel="noreferrer"
                            target="_blank"
                            className="bg-[#FFCF05] py-1 px-5 text-black font-bold"
                            >
                                Buy Now
                            </a>
                        </div>
                        )}
                    </div>
                    )}
                    {/* <span className=" text-white capitalize mt-5 hidden md:block">
                    {item.name}
                    </span> */}
                </Item>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Merch;
