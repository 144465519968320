import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase5";
import { useNavigate } from "react-router-dom";
import { setCookie, getCookie } from "../../util";

export default function Home() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const siteRedirected = getCookie('splash');

  //   if (siteRedirected === 'site-redirected') {
  //     console.log('redirect');
  //   } else {
  //     // setCookie("splash", "site-redirected", ".700sandbox.com", 0.003472221); // set cookie to 5 mins
  //     setCookie("splash", "site-redirected", window.location.hostname, 0.003472221); // set cookie to 5 mins

  //     navigate('/lovedones');
  //   }
  // }, []);
  
  return (
    <Box className="text-white">
      <Showcase />
    </Box>
  );
}
