import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./data";
import styled from "styled-components";

const SocialLink = styled.a``;

interface SocialIconProps {
  pandoraIconWidth?: string;
  iconWidth?: string;
}

export default function SocialIcons({
  iconWidth,
  pandoraIconWidth,
}: SocialIconProps) {
  return (
    <ul className="flex flex-row justify-center items-center text-[#f00]">
      {data.map((item, idx) => (
        <SocialLink
          key={idx}
          href={item.link}
          rel="noreferrer"
          target="_blank"
          className="hover:text-[#f00] text-2xl xl:text-3xl m-4 xl:m-5 rounded-lg w-8 md:w-4 h-4 xl:w-8 xl:h-6 flex justify-center items-center"
        >
          {item.icon ? (
            <FontAwesomeIcon icon={item.icon} />
          ) : item.isPandora ? (
            <img
              src={item.img}
              className={pandoraIconWidth + "hidden"}
              style={{
                filter:
                  "invert(8%) sepia(99%) saturate(6379%) hue-rotate(355deg) brightness(90%) contrast(94%)",
              }}
            />
          ) : (
            <img
              src={item.img}
              className="h-5 md:h-3 xl:h-6"
            />
          )}
        </SocialLink>
      ))}
    </ul>
  );
}
