import { data } from "./data";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import { useState } from "react";
// import LOGO_IMG from "../../assets/images/logo.png";
import useGlobalService from "../../app/Hooks/useGlobalService";

const Container = styled.div`
  z-index: 20;
`;

export default function Navigation() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { setIsScrollableVideoEnded } = useGlobalService();

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.isRoute ? (
      <ScrollLink
        key={idx}
        to={item.name} // id of the section
        smooth
        duration={1000}
        spy={true}
        onClick={() => {
          setIsActive(false);
          setIsScrollableVideoEnded(true);
        }}
        className="mx-2 md:mx-5 my-5 font-bold cursor-pointer text-4xl"
      >
        {item.name}
      </ScrollLink>
    ) : (
      <a
        key={idx}
        // href={item.to} // route
        onClick={() => {
          setIsActive(false);
          setTimeout(function() {
            window.location.href = item.to;
          }, 300);
        }}
        className="my-2 font-bold tracking-tight text-2xl xl:text-4xl cursor-pointer"
        dangerouslySetInnerHTML={{__html: item.name}}
      >
      </a>
    )
  );

  return (
    <Container className="mx-auto flex flex-col justify-center items-start text-white bg-none bg-opacity-60 px-5 absolute top-0 left-0 w-full py-5 md:py-10 lg:px-10 z-50">
      <div className="w-auto flex justify-start items-start">
        {/* desktop navigation */}
        <div className="uppercase justify-between items-center flex-row hidden md:hidden">
          {menuItems}
        </div>
        {/* tablet navigation */}
        <ScrollLink
          to="home" // id of the section
          smooth
          duration={1000}
          spy={true}
          className="cursor-pointer font-extrabold text-2xl md:text-5xl flex flex-row justify-center items-center mr-auto md:hidden"
        >
          {/* <img src={LOGO_IMG} alt="logo" className="w-10 md:w-16 mr-2" /> */}
        </ScrollLink>
        <div
          className={`uppercase justify-center items-center flex-col flex fixed top-0 bg-opacity-80 transition-all w-full h-screen bg-black
            ${isActive ? "right-0" : "-right-full"}
        `}
        >
          {menuItems}
        </div>
        {/* burger */}
        <div
          className={`flex flex-col z-50 cursor-pointer ${
            isActive ? "mt-3 md:mt-4" : ""
          }`}
          aria-label="button"
          onClick={handleClick}
        >
          <div
            className={`w-10 h-1 md:w-16 md:h-2.5 rounded-sm ${
              isActive ? "rotate-45 bg-white" : `rotate-0 ${window.location.pathname.includes('tour') ? "bg-[#FFFFFF]" : "bg-[#000000]"}`
            } transition-all`}
          />
          <div
            className={`w-10 h-1 md:w-16 md:h-2.5 rounded-sm mt-1.5 md:mt-2 ${
              isActive ? "hidden bg-white" : `block ${window.location.pathname.includes('tour') ? "bg-[#FFFFFF]" : "bg-[#000000]"}`
            }`}
          />
          <div
            className={`w-10 h-1 md:w-16 md:h-2.5 rounded-sm ${
              isActive ? "-rotate-45 absolute bg-white" : `rotate-0 mt-1.5 md:mt-2 ${window.location.pathname.includes('tour') ? "bg-[#FFFFFF]" : "bg-[#000000]"}`
            } transition-all`}
          />
        </div>
      </div>
    </Container>
  );
}
