import { Box } from "../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import YoutubeSlider from "../../components/YoutubeSlider/YoutubeSlider";
import $ from "jquery";
import styled from "styled-components";
import Button from "../../components/Form/Button";
import GLOBE from "../../assets/images/Please Don't Cry International Tour Globe 24fps v3.gif";
import TICKET from "../../assets/images/Buy_Tickets.png";
import { isMobile } from "../../constants";

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5geodata_continentsLow from "@amcharts/amcharts5-geodata/continentsLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_data_countries from "@amcharts/amcharts5-geodata/data/countries";
import am5geodata_data_countries2 from "@amcharts/amcharts5-geodata/data/countries2";

import { data, includedCountries } from "./data";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { PollingWatchKind } from "typescript";

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
}

const Item = styled.div``;

const TourDate = styled.div``;

const Tour = () => {
    return (
        <Box
        className={`relative flex flex-col xl:flex-row justify-center items-center w-full h-min-screen xl:h-screen pt-10 md:py-5 bg-[#000000]`}
        >
            <Box className={`text-white text-[2rem] leading-[2.25rem] text-center pt-10 block xl:hidden uppercase`}>
                Please Don't Cry<br />International Tour
            </Box>
            <Box className={`relative flex flex-col-reverse xl:flex-row justify-center items-center h-full w-full xl:mx-20 3xl:mx-36 mb-10 xl:my-10 text-white`}>
                <Box className="w-11/12 xl:w-8/12">
                    {data.map((item, idx) => (
                        <>
                            <Box className={`text-3xl xl:text-[2.35rem] xl:leading-[2.55rem] 3xl:text-[3.25rem] 3xl:leading-[3.5rem] text-center pt-10 xl:pt-0 xl:pr-48 3xl:pr-56 xl:pb-10 uppercase ${idx < 1 ? "hidden xl:block" : "hidden"}`}>
                                Please Don't Cry<br />International Tour
                            </Box>
                            <Item key={idx} id={`item_${item.id}`} className="xl:mb-5">
                                <Box
                                    className="flex justify-between xl:justify-start items-center mt-10 xl:mt-0"
                                    onClick = {() => {
                                        // handleClick(item.id);
                                    }}
                                >
                                    <h2 className="text-3xl xl:text-4xl 3xl:text-5xl mb-5">{item.title}</h2>
                                    {/* <img src={TICKET} className={`w-32 xl:w-40 3xl:w-48 ml-10 xl:mb-3 ${((item.tours.length > 0 && item.tours[0].date !== "coming soon") && item.title == "North America") ? 'block' : 'hidden'}`} /> */}
                                </Box>
                                <Box id={`container_${item.id}`} className="columns-1 xl:columns-2 gap-2">
                                    {item.tours.map((tour, idx2) => (
                                        <>
                                            {!tour.date ? null : (
                                                <TourDate
                                                    key={idx2}
                                                    className="font-ocr-b text-[0.6rem] xl:text-[0.6rem] 3xl:text-[0.7rem] mb-1 uppercase cursor-pointer"
                                                    onClick={() => {
                                                        if (tour.link) window.open(tour.link);
                                                    }}
                                                >
                                                    {tour.date} <span className="text-[#FED500]">{tour.location}</span> {tour.place}
                                                </TourDate>
                                            )}
                                        </>
                                    ))}
                                </Box>
                            </Item>
                        </>
                    ))}
                </Box>
                <Box className="w-3/4 xl:w-7/12 flex justify-center items-center">
                    <img src={GLOBE} className="w-full xl:w-11/12" />
                </Box>
            </Box>
        </Box>
    );
};

export default Tour;
